// import '../css/app.css'
import 'jquery'
import '../vendor/nucleo/css/nucleo.css'
import '../vendor/@fortawesome/fontawesome-free/css/all.min.css'
import '../css/argon.min.css?v=1.2.0.css' 
import 'bootstrap-select'
import '../vendor/bootstrap/dist/js/bootstrap.bundle.min.js'
import 'js-cookie'
import '../vendor/jquery.scrollbar/jquery.scrollbar.min.js'
import '../vendor/jquery-scroll-lock/dist/jquery-scrollLock.min.js'
import './argon.js?v=1.2.0'
import './demo.min.js'
